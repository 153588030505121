import './footer.scss';
import { Link } from 'react-router-dom';

function Footer() {
  const year = new Date().getFullYear().toString();

  return (
    <footer className='footer'>
        <div className='footer-container'>
          <p>
            © {year} <Link to="/" className='link-effect'>История Народа</Link> | Разработка: <a href='https://gavriil-samarin.ru/' target="_blank" className='link-effect'>Гавриил Самарин</a>
          </p> 

          <div className='footer-container-counter'>
            <a href='https://nasha-istoriya.ru/' target={'_blank'}>
              <img className='footer-container-counter__img' src='/images/counter/БаннерНИ.png' title='Наша История'></img>
            </a>
          </div>
        </div>

        <div className='footer__lenta'>
            <img src='/images/GL.png' alt='' />
        </div>
    </footer>
  );
}

export default Footer;
export const objects = [
    {
      "id": "1",
      "title":"Танк-памятник Т-34",
      "location":"Эстония, г. Нарва",
      "desc":"Символ освобождения города в период Второй мировой войны",
      "foundDate":"09.05.1970",
      "demDate":"16.08.2022",
      "img":"/images/objects/t34.jpg",
      "link": "/эстония/т-34",
      "reg": "est"
    },
    {
      "id": "2",
      "title":"Памятник Освободителям Риги",
      "location":"Латвия, г. Рига",
      "desc":"Посвящён свободителям Советской Латвии и Риги от немецко-фашистских захватчиков",
      "foundDate":"",
      "demDate":"",
      "img":"/images/objects/riga.jpg",
      "link": "/латвия/памятник-освободителям-риги",
      "reg": "lat"
    },
    {
      "id": "3",
      "title":"Мемориал советским воинам-освободителям",
      "location":"Литва, г. Клайпеда",
      "desc":"«Убитым и погибшим узникам фашистских лагерей»",
      "foundDate":"",
      "demDate":"",
      "img":"/images/objects/klaipeda.jpg",
      "link": "/литва/мемориал-советским-воинам-освободителям",
      "reg": "lit"
    },
    {
      "id": "4",
      "title":"Памятник Герою Советского Союза Маншук Маметовой",
      "location":"Казахстан, г. Уральск",
      "desc":"Будучи тяжело раненой, она смогла устранить больше 70 гитлеровцев",
      "foundDate":"",
      "demDate":"",
      "img":"/images/objects/manshuk.jpg",
      "link": "/казахстан/памятник-герою-советского-союза-маншук-маметовой",
      "reg": "kaz"
    },
    {
      "id": "5",
      "title":"Памятник Герою Советского Союза Ивану Васильевичу Панфилову",
      "location":"Киргизия, г. Бишкек",
      "desc":"???",
      "foundDate":"",
      "demDate":"",
      "img":"/images/objects/panfilov.jpg",
      "link": "",
      "reg": "kir"
    },
    {
      "id": "6",
      "title":"Памятник полководцу Александру Суворову",
      "location":"Украина, г. Одесса",
      "desc":"Именно по его планам была основана Одесса, жители которой сейчас называют его \"колонизатором\"",
      "foundDate":"",
      "demDate":"",
      "img":"/images/objects/suvorov.jpg",
      "link": "/украина/памятник-полководцу-александру-суворову",
      "reg": "ukr"
    },
    {
      "id": "7",
      "title":"Памятник Александру Матросову",
      "location":"Украина, г. Днепропетровск",
      "desc":"Погиб в 19 лет, закрыв своей грудью амбразуру немецкого дота, дав возможность бойцам своего взвода атаковать опорный пункт фашистов",
      "foundDate":"",
      "demDate":"",
      "img":"/images/objects/matrosov.jpg",
      "link": "/украина/памятник-александру-матросову",
      "reg": "ukr"
    },
    {
      "id": "8",
      "title":"Памятник Валерию Чкалову",
      "location":"Украина, г. Днепропетровск",
      "desc":"Советский лётчик-испытатель, Герой Советского Союза. Совершил первый беспосадочный перелёт через Северный полюс",
      "foundDate":"",
      "demDate":"",
      "img":"/images/objects/chkalov.jpg",
      "link": "",
      "reg": "ukr"
    }
]
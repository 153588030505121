import './contacts.scss';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

function Contacts() {
  return (
    <div className='contacts'>
      <Header />

      <div className='contacts-page'>
        <div className='contacts-page-content'>
          <h1>Контакты</h1>

          <div className='contacts-page-content-info'>
            <a href='mailto:samaringavriil@yandex.ru' className='contacts-page-content-info-item'>
              <div className='contacts-page-content-info-item-block'>
                <img src='/images/contacts/email.png' alt='Почта' />

                <p className='contacts-page-content-info-item-block-text'>
                  Электронная почта <br />
                  samaringavriil@yandex.ru
                </p>
              </div>
            </a>

            <a href='https://vk.com/gavriilsamarin' className='contacts-page-content-info-item'>
              <div className='contacts-page-content-info-item-block'>
                <img src='/images/contacts/vk.png' alt='ВКонтакте' />

                <p className='contacts-page-content-info-item-block-text'>
                  ВКонтакте <br />
                  @gavriilsamarin
                </p>
              </div>
            </a>

            <a href='https://t.me/GavriilSamarin' className='contacts-page-content-info-item'>
              <div className='contacts-page-content-info-item-block'>
                <img src='/images/contacts/tg.png' alt='Telegram' />
                
                <p className='contacts-page-content-info-item-block-text'>
                  Telegram <br />
                  @gavriilsamarin   
                </p>
              </div>
            </a>

            <a href='/files/История Народа.exe' className='contacts-page-content-info-item'>
              <div className='contacts-page-content-info-item-block'>
                <img src='/images/contacts/windows.png' alt='Windows' />
                
                <p className='contacts-page-content-info-item-block-text'>
                  Приложение для ПК
                </p>
              </div>
            </a>

            <a href='/files/IstoriyaNaroda.apk' className='contacts-page-content-info-item'>
              <div className='contacts-page-content-info-item-block'>
                <img src='/images/contacts/android.png' alt='Android' />
                
                <p className='contacts-page-content-info-item-block-text'>
                  Мобильное приложение (Android)
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contacts;
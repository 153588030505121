import './objectsMain.scss';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import ObjectCard from '../../../components/ObjectCard';
import { objects } from '../../../objectList.js';

function objectsMain() {
  return (
    <div className='objectsMain'>
      <Header />

      <div className='objectsMain-page'>
        <h1 className='objectsMain-page-title'>Список объектов</h1>

        <div className='objectsMain-page-list'>
          {
            objects.map(item => {
              return (
                <ObjectCard 
                  img={item.img}
                  title={item.title}
                  desc={item.desc}
                  link={item.link}
                  location={item.location}
                />
              )
            })
          }
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default objectsMain;
import './objectcard.scss';

function ObjectCard({ img, title, desc, link, location }) {
    return (
        <div className='card'>
            <a className='card-link' href={link}>
                <div className="card-link-box">
                    <img className='card-link-box__img' src={img} alt="Мемориал" />

                    <h1 className="card-link-box__title">{title}</h1>

                    <h3 className='card-link-box__city'>{location}</h3>

                    <p className='card-link-box__desc'>{desc}</p>
                </div>
            </a>
        </div>
    );
}
export default ObjectCard;
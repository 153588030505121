import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./slider.scss";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function App() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="photo-blacker"></div>
          <img src="/images/slider/t34.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <div className="photo-blacker"></div>
          <img src="/images/slider/riga.jpg" />
        </SwiperSlide>
        <SwiperSlide>
          <div className="photo-blacker"></div>
          <img src="/images/slider/klaipeda.jpg" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
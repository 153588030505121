import './reset.css';
import './App.scss';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './pages/home/home';
import Armenia from './pages/regions/armenia/Armenia';
import Azerbaijan from './pages/regions/azerbaijan/Azerbaijan';
import Belarus from './pages/regions/belarus/Belarus';
import Estonia from './pages/regions/estonia/Estonia';
import Georgia from './pages/regions/georgia/Georgia';
import Kazakhstan from './pages/regions/kazakhstan/Kazakhstan';
import Kyrgyzstan from './pages/regions/kyrgyzstan/Kyrgyzstan';
import Latvia from './pages/regions/latvia/Latvia';
import Lithuania from './pages/regions/lithuania/Lithuania';
import Moldova from './pages/regions/moldova/Moldova';
import Russia from './pages/regions/russia/Russia';
import Tadjikistan from './pages/regions/tadjikistan/Tadjikistan';
import Turkmenistan from './pages/regions/turkmenistan/Turkmenistan';
import Ukraine from './pages/regions/ukraine/Ukraine';
import Uzbekistan from './pages/regions/uzbekistan/Uzbekistan';
import ObjectsPage from './pages/objects/objectsMain/objectsMain';
import About from './pages/about/about';
import Contacts from './pages/contacts/contacts';
import ObjectSample from './pages/objects/objectSample/ObjectSample';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/объекты' element={<ObjectsPage />} />
        <Route path='/армения' element={<Armenia />} />
        <Route path='/азербайджан' element={<Azerbaijan />} />
        <Route path='/беларусь' element={<Belarus />} />
        <Route path='/эстония' element={<Estonia />} />
        <Route path='/грузия' element={<Georgia />} />
        <Route path='/казахстан' element={<Kazakhstan />} />
        <Route path='/киргизстан' element={<Kyrgyzstan />} />
        <Route path='/латвия' element={<Latvia />} />
        <Route path='/литва' element={<Lithuania />} />
        <Route path='/молдова' element={<Moldova />} />
        <Route path='/россия' element={<Russia />} />
        <Route path='/таджикистан' element={<Tadjikistan />} />
        <Route path='/туркменистан' element={<Turkmenistan />} />
        <Route path='/украина' element={<Ukraine />} />
        <Route path='/узбекистан' element={<Uzbekistan />} />
        <Route path='/о-сайте' element={<About />} />
        <Route path='/контакты' element={<Contacts />} />

        {/* Объекты */}
        <Route path='/эстония/т-34' element={
          <ObjectSample 
            img="/images/objects/t34.jpg"
            title={"Танк-памятник Т-34"}
            text= {`
            Располагался он в Эстонии, на берегу реки Нарова по пути из города Нарва в Нарва-Йыэсуу. Он призывает помнить о событиях Великой Отечественной войны и сделать всё возможное, чтобы подобные трагедии больше никогда не повторялись.\n
            Торжественное открытие монумента состоялось 9 мая 1970 года. Танк Т-35-85 установлен в том самом месте, где части Красной Армии форсировали реку Нарова под командованием генерала Федюнинского. Происходило это в июле 1944 года. Форсирование реки Нарова стало переломным моментом в ходе Второй Мировой войны на территории Эстонии, предшествовавшее освобождению Нарвы, а затем и всей республики от немецко-фашистских захватчиков.\n
            Установка монумента была приурочена к 25-летию со дня Великой Победы. Настоящий танк установлен на изготовленном из доломита постаменте, высота которого составлет 2,2 метра. К постаменту прикреплена пояснительная табличка, на которой на эстонском и русском языках написан текст следующего содержания:\n
            "В этом районе войска Ленинградского фронта 25-26 июля 1944 года форсировали реку, прорвали оборону немецко-фашистских войск и освободили город Нарву."\n
            Памятник ликвидирован решением правительства Эстонии 16 августа 2022 года, несмотря на просьбы и пикеты местных жителей.
            `}
          />
        } />

        <Route path='/латвия/памятник-освободителям-риги' element={
          <ObjectSample 
            img="/images/objects/riga.jpg"
            title={"Памятник Освободителям Риги"}
            text={`
            Памятник воинам Советской армии - освободителям Советской Латвии и Риги от немецко-фашистских захватчиков расположен на юго-западе Риги в парке Победы. Монумент был открыт в 1985 году, носит неофициальное название Памятник победы. Автором концепции стал рижский художник-архитектор Александр Бугаев, скульпторы - народный художник Латвийской ССР Лев Буковский и Айварс Гулбис. Композиционно центром памятника является 79-метровая колонна, увенчанная золотыми пятиконечными звездами, по бокам которой расположены символические скульптурные изображения Матери-Родины и воинов-освободителей.\n
            Работы по демонтажу памятника начались 23 августа 2022 года.
            `}
          />
        }
        />

        <Route path='/литва/мемориал-советским-воинам-освободителям' element={
          <ObjectSample 
            img="/images/objects/klaipeda.jpg"
            title={"Мемориал советским воинам-освободителям"}
            text={`
            В январе 1945 года советские войска начали решительное наступление в Восточной Пруссии, и гитлеровское командование было вынуждено сосредоточиться на обороне Кенигсберга. Создались благоприятные условия для нанесения решающего удара по группировке противника в районе Клайпеды. В ожесточенных боях за янтарное побережье Литвы принимали участие представители всех братских республик Советского Союза, показавшие примеры массового героизма.\n
            28 января 1945 года Клайпеда была освобождена.\n
            Останки погибших воинов - освободителей перенесены из разных захоронений в братскую могилу, реконструкция которых осуществлена в 1975 году. Высоко в небо взметнулся памятник- между двумя щитами повисший меч, рукоять которого опоясывает гирлянда из дубовых листьев. Памятник словно символ: сегодня мирное небо, но, если кто с мечем придет, тот от меча и погибнет. Перед памятником – Вечный огонь, за которым на гранитных плитах высечены фамилии похороненных советских солдат. Семь отдельных плит – с именами Героев Советского Союза.\n
            На памятнике высечены на литовском и русском языках поэтические строки Саломеи Нерис: «Я буду с вами в подвигах труда и в мирном шелесте краснознаменном».\n
            Решение о демонтаже мемориала советским воинам на улице Дауканто было принято городским советом Клайпеды 22 июня 2022 года.
            `}
          />
        }
        />

        <Route path='/казахстан/памятник-герою-советского-союза-маншук-маметовой' element={
          <ObjectSample 
            img="/images/objects/manshuk.jpg"
            title={"Памятник Герою Советского Союза Маншук Маметовой"}
            text={`
            Маншук Маметова приняла твердое решение идти на фронт сразу после своего совершеннолетия. Маметова почти целый год добивалась от военкомата, чтобы ее отправили на войну. Желание настойчивой девушки в конце концов было удовлетворенно.Попав в состав Красной Армии, она оказалась в штабе 100 Казахской бригады. Вначале Маншук Жиенгалиевна Маметова работала там писарем, а затем стала выполнять обязанности медсестры. Но это совершенно не устраивало девушку, и уже через месяц в звании старшего сержанта ее переводят в один из стрелковых батальонов гвардейской стрелковой дивизии №21. \n
            В день, когда свершился легендарный подвиг Маншук Маметовой, ее батальон получил из штаба приказ отбить атаку врага около Невеля. Враг сразу же обрушил шквальный огонь минометов и артиллерии по позициям советского батальона. Но, сдерживаемые огнем русских пулеметов, немцы отступили. Во время своей стрельбы девушка не сразу заметила, как стихли два соседних пулемета. Она поняла, что ее товарищей больше нет в живых, и стала поочередно стрелять из трех орудий сама, переползая от своего пулемета к соседним. После того как гитлеровцы смогли сориентироваться, они навели свои минометы на позицию Маншук. Разорвавшаяся рядом мина опрокинула пулемет девушки, и Маметова была ранена в голову. Она потеряла сознание. Когда Маншук пришла в себя, то поняла, что радостные немцы перешли в наступление. Она доползла к соседнему пулемету и продолжила атаку. Будучи тяжело раненой, она смогла своей стрельбой устранить больше 70 гитлеровцев, что обеспечило успешное дальнейшее продвижение нашим силам. От полученного ранения героиня скончалась на поле боя.\n
            22 мая 2017 года начались процессы по демонтажу памятника, располагавшемуся на площади более 30 лет.
            `}
          />
        }
        />

        <Route path='/украина/памятник-полководцу-александру-суворову' element={
          <ObjectSample 
            img="/images/objects/suvorov.jpg"
            title={"Памятник полководцу Александру Суворову"}
            text={`
            Генералиссимус Александр Васильевич Суворов родился в 1729 (или 1730) году и рос болезненным ребенком. Несмотря на это, будущность маленького дворянина была определена заранее – в возрасте 17 лет он был зачислен мушкетером в лейб-гвардии Семеновский полк. Так началась длинная и славная военная карьера Суворова.\n
            В течение полувека он руководил войсками в 60 крупных боях и сражениях с турками, татарами, ногайцами, пруссаками, поляками, французами и не потерпел ни одного поражения, причем всегда противник превосходил численностью. Это было следствием не только военной удачи и смелости – Суворов глубоко занимался развитием военной науки и внес в нее большой вклад.\n
            Так уж сложилось, что наибольших успехов Александр Васильевич достиг в войнах с турками, при этом каждая его победа – при Козлудже, Кинбурне, Фокшанах, Рымнике, взятие Очакова и Измаила – заставляла Османскую империю заключать невыгодный для себя мир и уступать России все новые и новые земли.\n
            Именно поэтому роль Александра Суворова в освоении Российской империей Новороссии, Крыма и Кубани огромна.\n
            Благодаря его победам Россия сумела удержать свою новые южные губернии, он первым оценил стратегическую важность Севастопольской бухты, по планам Суворова была основана Одесса и Тирасполь, построена Кубанская защитная линия, укреплялись города и крепости Николаев, Таганрог, Мариуполь, Херсон, Керчь.\n
            Решение о демонтаже монумента было принято 24 ноября 2022 года – в день рождения великого полководца.
            `}
          />
        }
        />

        <Route path='/украина/памятник-александру-матросову' element={
          <ObjectSample 
            img="/images/objects/matrosov.jpg"
            title={"Памятник Александру Матросову"}
            text={`
            Александр Матросов погиб в 19 лет во время Великой Отечественной войны, закрыв собой амбразуру немецкого дзота. Он является уроженцем Днепра, который во время его рождения еще назывался Екатеринослав. Пушкин командовал корпусом, который освобождал Днепропетровск от немецко-фашистских захватчиков.\n
            4 января 2023 года мемориал был демонтирован.
            `}
          />
        }
        />

        <Route path='/латвия/памятник-освободителям-риги' element={
          <ObjectSample 
            img=""
            title={""}
            text={`
            
            `}
          />
        }
        />

      </Routes>
    </div>
  );
}

export default App;

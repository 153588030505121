import './header.scss';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className='header'>
      <div className='header-container'>
        <Link to="/">
          <h1 className='header-container__logo'>История Народа</h1>
        </Link>
        
        <div className='header-container__lenta'>
            <img src='/images/GL.png' alt='' />
        </div>

        <nav className='header-container-nav'>
          <ul className='header-container-nav-list'>
            <Link to="/объекты" style={{textDecoration: 'none'}}>
              <li className='header-container-nav-list__item'>ОБЪЕКТЫ</li>
            </Link>
            <Link to="/о-сайте" style={{textDecoration: 'none'}}>
              <li className='header-container-nav-list__item'>О САЙТЕ</li>
            </Link>
            <Link to="/контакты" style={{textDecoration: 'none'}}>
              <li className='header-container-nav-list__item'>КОНТАКТЫ</li>
            </Link>
          </ul>
        </nav>
      </div>

      <marquee>
        Наш проект, посвящённый историческим и культурным объектам РФ: «<a href='https://nasha-istoriya.ru/'>Наша История</a>»
      </marquee>
    </header>
  );
}

export default Header;
import '../../objects/objectsMain/objectsMain.scss';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { objects } from '../../../objectList';
import ObjectCard from '../../../components/ObjectCard';

function Azerbaijan() {
  return (
    <div className='objectsMain'>
      <Header />

      <div className='objectsMain-page'>
        <h1 className='objectsMain-page-title'>Азербайджан<br/>Список объектов</h1>

        <div className='objectsMain-page-list'>
          {
            objects.map(item => {
              if (item.reg == "aze") {
                return (
                  <ObjectCard 
                    img={item.img}
                    title={item.title}
                    desc={item.desc}
                    link={item.link}
                    location={item.location}
                  />
                )
              }
            })
          }
        </div>
      </div>
      
      <div className='footer-page'>
        <Footer />
      </div>
    </div>
  );
}

export default Azerbaijan;
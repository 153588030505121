import './about.scss';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

function About() {
  return (
    <div className='about'>
      <Header />

      <div className='about-page'>
        <div className='about-page-content'>
          <h1>О сайте</h1>

          <div className='about-page-content-text'>
            <p>
              <b>История Народа</b> - познавательный, патриотический интернет-ресурс, который существует с целью увековечения памяти о подвиге народа, который смог отстоять и уберечь своё Отечество от стоявших перед ним угроз и испытаний.
              <br /> 
              На сегодняшний день, многие, отделившиеся от нас государства, не прошли "испытание временем" - активно занимаются разрушением и осквернением мемориалов, посвящённых своим же соотечественникам.
              <br /> <br />
              На страницах этого сайта будет храниться подробная информация о демонтированных объектах и памятниках, которые посвящены подвигам наших героев. Можно узнать полную историю монумента, чему или кому он посвящён, где располагался и как выглядел при существовании.
              <br />Тем самым, будет набираться архив, информацией из которого может воспользоваться любой посетитель этого сайта.
              <br /> <br />
              <figure>
                <blockquote>
                  История — это не учительница, а надзирательница: она ничему не учит, но сурово наказывает за незнание уроков.
                </blockquote>
                <figcaption>
                  &mdash; <cite>Василий Осипович Ключевский</cite>
                </figcaption>
              </figure>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
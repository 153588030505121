import './object.scss';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { objects } from '../../../objectList.js';

function ObjectSample({ img, title, text }) {
  return (
    <div className='ObjectSample'>
      <Header />

      <div className='ObjectSample-main'>
        <div className='ObjectSample-main-desc'>
          <img src={img} alt='Объект' />

          <h1 className='ObjectSample-main-desc-title'>{title}</h1>

          <div className='ObjectSample-main-desc-text'>
            {text}
          </div>
        </div>
      </div>
      
      <div className='ObjectSample-footer'>
        <Footer />
      </div>
    </div>
  );
}

export default ObjectSample;